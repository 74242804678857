import React, { useState, useEffect } from 'react';
import apiClient from '../utils/apiClient';
import axios from 'axios';
import { Container, TextField, Button, Typography, Box, Avatar, Link, Grid2, Alert, Checkbox, FormControlLabel, CircularProgress } from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { Link as RouterLink } from 'react-router-dom'; // Import Link from React Router
import { useNavigate, useLocation } from "react-router-dom";
import { loadStripe } from '@stripe/stripe-js';

// Initialize Stripe with your publishable key
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY!);


const Register: React.FC = () => {
  const location = useLocation();
  const origin = location.state?.origin;

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [password, setPassword] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [message, setMessage] = useState('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [termsAccepted, setTermsAccepted] = useState(false); // Track checkbox
  const [referralCode, setReferralCode] = useState(''); // New state for referral code
  const [filter, setFilter] = useState(null);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const baseURL = process.env.REACT_APP_BACK_END_URL;

  const isValidEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  // Phone number validation function using regex
  const isValidPhoneNumber = (phone: string) => {
    const phoneRegex = /^[0-9]{10}$/; // Example: simple 10-digit number validation
    return phoneRegex.test(phone);
  };

  useEffect(() => {
    // Check if a referral code is present in the URL
    const searchParams = new URLSearchParams(location.search);
    const code = searchParams.get('referral');
    if (code) {
      setReferralCode(code); // Set the referral code state
    }

    const storedFilter = localStorage.getItem('newFilter');
    if (storedFilter) {
      setFilter(JSON.parse(storedFilter));
      localStorage.removeItem('newFilter'); // Clear it after retrieval if not needed
    }
  }, [location]);

  const handleRegister = async (e: React.FormEvent) => {
    e.preventDefault();
    setMessage('');
    setLoading(true);

    if (!firstName || !lastName || !email || !phoneNumber || !password || !confirmPassword) {
      setMessage("Please fill in all required fields.");
      setLoading(false);
      return;
    }

    if (!isValidEmail(email)) {
      setMessage("Please enter a valid email address.");
      setLoading(false);
      return;
    }

    // Validate phone number format
    if (!isValidPhoneNumber(phoneNumber)) {
      setMessage("Please enter a valid phone number (10 digits).");
      setLoading(false);
      return;
    }

    if (password !== confirmPassword) {
      setMessage("Passwords do not match.");
      setLoading(false);
      return;
    }

    if (!termsAccepted) {
      setMessage("You must accept the terms and conditions to register.");
      setLoading(false);
      return;
    }

    try {
      const register_response = await axios.post(`${baseURL}/register/`, { password, firstName, lastName, email, phoneNumber, referralCode, filter });
      localStorage.setItem('access_token', register_response.data.access);
      localStorage.setItem('refresh_token', register_response.data.refresh);

      if (register_response.data.success) {
          try {
            var withTrial = origin == "trial" ? true : false;
            const response = await apiClient.post('/create-checkout-session/', { trial: withTrial});

            // Redirect to Stripe Checkout
            const stripe = await stripePromise;
            await stripe!.redirectToCheckout({
              sessionId: response.data.id,
            });
          } catch (error) {
            console.error("Error in creating checkout session:", error);
          }
      } else {
        setMessage(register_response.data.error);
      }

    } catch (error: any) {
      setMessage(error.response.data.message);
    }
    setLoading(false);
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign Up
        </Typography>
        <Box component="form" onSubmit={(e) => e.preventDefault()} sx={{ mt: 1 }}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="firstName"
            label="First Name"
            name="firstName"
            autoComplete="given-name"
            autoFocus
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="lastName"
            label="Last Name"
            name="lastName"
            autoComplete="family-name"
            autoFocus
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="phoneNumber"
            label="Phone Number"
            name="phoneNumber"
            autoComplete="tel"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="new-password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="confirmPassword"
            label="Confirm Password"
            type="password"
            id="confirm-password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="referralCode"
            label="Referral Code"
            name="referralCode"
            value={referralCode}
            onChange={(e) => setReferralCode(e.target.value)}
          />
          <FormControlLabel
            control={
              <Checkbox
                name="termsAccepted"
                checked={termsAccepted}
                onChange={(e) => setTermsAccepted(e.target.checked)}
                required
              />
            }
            label={
              <Typography variant="body2">
                I agree to the{' '}
                <Link href="/terms-and-conditions" target="_blank" rel="noopener">
                  Terms & Conditions
                </Link>{' '}
                and the{' '}
                <Link href="/privacy-policy" target="_blank" rel="noopener">
                  Privacy Policy
                </Link>. I understand that message and data rates may apply, and I will receive notifications about tee times at a frequency of approximately 2-5 times per week. To stop receiving messages, I can reply with "STOP" at any time. For customer care, please contact{' '}
                <Link href="mailto:contact@quick-tee.com">contact@quick-tee.com</Link>.
              </Typography>
            }
            sx={{ alignItems: 'flex-start', mb: 2 }}
          />
          {message && (
            <Alert severity="error" sx={{ width: '100%', mt: 2 }}>
              {message}
            </Alert>
          )}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            sx={{ mt: 3, mb: 2 }}
            onClick={handleRegister}
            disabled={loading}
          >
            {loading ? <CircularProgress size={24} sx={{ color: 'white' }} /> : "Sign Up"}
            </Button>
          <Grid2 container>
            <Grid2>
              <Typography variant="body2" color="textSecondary">
                Already have an account?{' '}
                <Link component={RouterLink} to="/login" variant="body2">
                  Sign In
                </Link>
              </Typography>
            </Grid2>
          </Grid2>
        </Box>
      </Box>
    </Container>
  );
};

export default Register;
