import { Box, Typography, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { useAccount } from '../contexts/AccountContext';
import RegisterButton from '../components/RegisterButton';

const PrelaunchRegistered = () => {
    const { group, subscriptionStatus } = useAccount();
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100vh',
                backgroundColor: '#f5f5f5',
                p: 3,
            }}
        >
            {group === "default" && subscriptionStatus === "pending" ? (
                <>
                    {/* Icon */}
                    <AccessTimeIcon sx={{ fontSize: 50, color: '#f44336' }} />

                    {/* Title */}
                    <Typography variant="h4" sx={{ mt: 2, fontWeight: 'bold' }}>
                        Preregistration Required
                    </Typography>

                    {/* Message */}
                    <Typography variant="body1" sx={{ mt: 2, textAlign: 'center', maxWidth: '600px' }}>
                        It looks like you haven't preregistered yet. To access our services when we launch,
                        please preregister now. We're excited to have you on board!
                    </Typography>

                    {/* Call to Action */}
                    <RegisterButton />
                </>
            ) : (

                <>
                    {/* Icon */}
                    <AccessTimeIcon sx={{ fontSize: 50, color: '#4caf50' }} />

                    {/* Title */}
                    <Typography variant="h4" sx={{ mt: 2, fontWeight: 'bold' }}>
                        You're All Set!
                    </Typography>

                    {/* Message */}
                    <Typography variant="body1" sx={{ mt: 2, textAlign: 'center', maxWidth: '600px' }}>
                        Thank you for preregistering! We are excited to announce that Quick Tee will officially launch on March 1, 2025.
                        You will have full access to our services starting from that day. Stay tuned and get ready to enjoy the convenience of our service!
                    </Typography>

                    {/* Call to Action */}
                    <Button
                        component={Link}
                        to="/"
                        variant="contained"
                        color="primary"
                        sx={{ mt: 3 }}
                    >
                        Go Back to Home
                    </Button>
                </>
            )}
        </Box>
    );
};

export default PrelaunchRegistered;
