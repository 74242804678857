import React, { useState } from 'react';
import { Box, Button, Modal, Typography } from '@mui/material';
import GolfCourseIcon from '@mui/icons-material/GolfCourse';

import apiClient from '../utils/apiClient';
import CourseSearch from '../components/CourseSearch';
import { FilterType } from '../types/FilterType';
import { useNavigate } from 'react-router-dom';
import CourseRequest from '../components/CourseRequest';

const PreregisterSurvey = () => {
    const navigate = useNavigate();

    const [newFilter, setNewFilter] = useState<FilterType>({
        num_of_players: 1,
        courses: [],
        date_start: '',
        date_end: '',
        days_of_week: '',
        time_gte: '',
        time_lte: '',
        size: -1,
        user: ''
    });
    const [courseRequestCount, setCourseRequestCount] = useState(0);
    const [openModal, setOpenModal] = useState(false);
    const [openRequestModal, setOpenRequestModal] = useState(false);
    

    const handleCloseModal = () => {
        setOpenModal(false)
    };

    const handleRequestCloseModal = () => {
        setOpenRequestModal(false)
    };

    const handleRequestCourse = () => {
        setOpenRequestModal(true);
    }

    const handleCreateFilter = async () => {
        try {
            const response = await apiClient.post('/filters/create/', newFilter);
            navigate('/dashboard');
        } catch (err) {
            console.log('Failed to create filter');
        }
    };

    const onNewCourseRequest = () => {
        setCourseRequestCount((prevCount) => prevCount + 1);
        if (courseRequestCount + 1 >= 3) {
            navigate('/dashboard');
        } else {
            setOpenModal(true)
        }
    }

    const finishNewCourseRequest = () => {
        navigate('/dashboard');
    }

    return (
        <div>
            <Box
                sx={{
                    maxWidth: 500,
                    mx: 'auto',
                    mt: 4,
                    p: 3,
                    border: '1px solid #ccc',
                    borderRadius: 2,
                    textAlign: 'center',
                    height: '400px',
                    display: 'flex', // Enable Flexbox
                    flexDirection: 'column', // Stack children vertically
                    justifyContent: 'space-between', // Space content evenly
                }}>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 2,
                    }}
                >
                    <GolfCourseIcon sx={{ fontSize: 50, color: 'secondary', mb: 2 }} />
                    <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 1 }}>
                        What is your main golf course?
                    </Typography>

                    <CourseSearch newFilter={newFilter} setNewFilter={setNewFilter} onNewCourseRequest={onNewCourseRequest} />
                    <Button variant="contained" color="secondary" sx={{ mt: 2 }} onClick={handleRequestCourse}>
                        Can't Find Your Course? Request It!
                    </Button>

                    <Button variant="contained" color="primary" onClick={handleCreateFilter}>
                        Submit
                    </Button>

                </Box>
            </Box>
            <CourseRequest onNewCourseRequest={onNewCourseRequest} handleCloseModal={handleRequestCloseModal} modalOpen={openRequestModal} />
            <Modal open={openModal} onClose={handleCloseModal}>
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 400,
                    bgcolor: 'background.paper',
                    p: 4,
                    boxShadow: 24,
                    borderRadius: '8px',
                }}>
                    <h2>Would you like to request another course?</h2>
                    <p>You can request up to three courses</p>

                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                        <Button onClick={finishNewCourseRequest} sx={{ mr: 2 }}>No</Button>
                        <Button variant="contained" onClick={handleCloseModal}>Yes</Button>
                    </Box>
                </Box>
            </Modal>
        </div>
    );
};

export default PreregisterSurvey;
