import React, { useEffect, useState } from 'react';
import {
  Box, Typography, Button, useMediaQuery,
  Container,
  Paper
} from '@mui/material';
import 'react-multi-carousel/lib/styles.css';

import { useTheme } from '@mui/material/styles';
import RegisterButton from '../components/RegisterButton';
import HowItWorks from '../components/HowItWorks';
import Pricing from '../components/Pricing';
import Spinner from '../components/Spinner';
import axios from 'axios';
import FilterForm from '../components/FilterForm';
import { FilterType } from '../types/FilterType';
import { useNavigate } from 'react-router-dom';

const Landing: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Detect mobile devices
  const [courses, setCourses] = useState([]);
  const baseURL = process.env.REACT_APP_BACK_END_URL;
  const navigate = useNavigate();
  const [newFilter, setNewFilter] = useState<FilterType>({
    num_of_players: 1,
    courses: [],
    date_start: '',
    date_end: '',
    days_of_week: '',
    time_gte: '',
    time_lte: '',
    size: -1,
    user: ''
  });
  const handleFormSubmit = () => {
    localStorage.setItem('newFilter', JSON.stringify(newFilter));
    navigate('/register', { state: { origin: 'trial' } });

  };


  const fetchCourses = async () => {
    try {
        const response = await axios.get(`${baseURL}/courses/`, {
        });
        setCourses(response.data.map((course: any) => course.formatted_name));
    } catch (error) {
        console.error("Error fetching courses:", error);
    }
};

useEffect(() => {
  fetchCourses();
}, []);

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' }, // Stack on mobile, side-by-side on larger screens
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '100vw',
          height: isMobile ? 'auto' : '70vh',
          pb: 30,
          pt: 5,
          background: `linear-gradient(to right, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0)),
                 url('/GolfCourse.jpeg') center/cover no-repeat`
        }}
      >
        {/* Text Section */}
        <Box
          sx={{
            padding: { xs: 2, md: 4 },
            textAlign: { xs: 'center', md: 'left' },
            // backgroundColor: 'green'
            width: { xs: '90%', md: '800px' }, // Fixed width on larger screens, responsive on mobile

          }}
        >
          <Typography
            variant={isMobile ? 'h3' : 'h2'}
            sx={{
              fontWeight: 'bold',
              color: '#EBECF0',
              mb: 2,
            }}
          >
            Never miss a tee-time again
          </Typography>

          <Typography
            variant={isMobile ? 'body1' : 'h5'}
            sx={{
              color: '#EEE',
              mb: 4,
              px: {xs: 8, md: 0},
            }}
          >
            Why refresh golf course websites all day? Set your preferences, and we'll notify you the second your ideal tee time opens up at your favorite courses!
          </Typography>
          <Box
            sx={{
              display: 'flex',
              gap: 2,
              justifyContent: { xs: 'center', md: 'flex-start' },
            }}
          >
            <RegisterButton />
            <Button
              onClick={() => {
                const element = document.getElementById('how-it-works');
                element?.scrollIntoView({ behavior: 'smooth' });
              }}
              sx={{
                backgroundColor: theme.palette.primary.main,
                color: '#fff',
                padding: '12px 24px',
                fontSize: isMobile ? '12px' : '18px',
                fontWeight: 'bold',
                borderRadius: '50px',
                boxShadow: '0px 5px 15px rgba(0,0,0,0.3)',
                transition: 'transform 0.3s, box-shadow 0.3s',
                '&:hover': {
                  backgroundColor: theme.palette.secondary.light,
                  transform: 'scale(1.05)',
                  boxShadow: '0px 8px 20px rgba(0,0,0,0.5)',
                },
              }}
            >
              Learn More
            </Button>
          </Box>
        </Box>

      </Box>

        <HowItWorks />

        {/* Filter Form */}
        <Box
          sx={{
            py: 6,
            backgroundColor: theme.palette.primary.main,
            color: 'white',
            textAlign: 'center',
            px: isMobile ? 2 : 0,
          }}
        >
          <Container>
            <Typography variant={isMobile ? 'h5' : 'h4'} sx={{ mb: 4 }}>
              Set your notification preferences
            </Typography>
            <Paper
              elevation={3}
              sx={{
                p: 4,
                border: '1px solid #ddd',
                mt: 4,
                borderRadius: '8px',
                maxWidth: '600px',
                margin: '0 auto', // Center align the box
              }}
            >

              <FilterForm
                newFilter={newFilter}
                setNewFilter={setNewFilter}
              />
              <Button
                variant="contained"
                color="primary"
                size={isMobile ? 'medium' : 'large'} // Smaller button on mobile
                sx={{ mt: 3 }}
                onClick={handleFormSubmit}
              >
                Create Filter
              </Button>
            </Paper>
          </Container>
        </Box>
 
        <Pricing />

        <Box
        sx={{
          backgroundColor: theme.palette.primary.main,
          color: 'white',
          p: 10
        }}
      >
        <Typography
                variant={isMobile ? 'h4' : 'h3'}
                textAlign={"center"}
                sx={{ fontWeight: 700, mb: 2, pb: 5 }}
            >
                New courses added daily
            </Typography>
        <Spinner courses={courses} />
        </Box>
    </Box>
  );
};

export default Landing;
