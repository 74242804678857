import React, { useEffect } from "react";
import { Link } from 'react-router-dom';

const NotFound = () => {
    useEffect(() => {
        document.title = "404 - Page Not Found";
      }, []);
    
    return (
        <div style={{ textAlign: 'center', marginTop: '50px' }}>
            <h1>404 - Page Not Found</h1>
            <p>Oops! The page you are looking for does not exist.</p>
            <Link to="/" style={{ textDecoration: 'underline', color: 'blue' }}>
                Go back to Home
            </Link>
        </div>
    );
};

export default NotFound;
