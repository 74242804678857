import React, { useState } from "react";
import { Box, Typography, LinearProgress, Button, Card, CardContent, Snackbar } from "@mui/material";

const REFERRAL_TARGET = 5; // Number of referrals needed to get the prize


const ReferralProgress = ({ referralCode, referralCount }: { referralCode?: string, referralCount: number }) => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const appURL = process.env.REACT_APP_BASE_URL;


  const handleCopyReferralCode = () => {
    navigator.clipboard.writeText(`${appURL}/register?referral=${referralCode}`);
    setSnackbarOpen(true);
  };
  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };


  const progress = (referralCount / REFERRAL_TARGET) * 100;

  return (
    <>
      <Card sx={{ textAlign: "center", p: 3, borderRadius: "20px", mt: 3 }}>
        <CardContent>
          <Typography variant="h5" gutterBottom>
            🎉 Your Referral Progress 🎉
          </Typography>

          <Typography variant="body1" sx={{ mb: 2 }}>
            You have referred <strong>{referralCount}</strong> {referralCount === 1 ? "friend" : "friends"}.
            {/* {referralCount >= REFERRAL_TARGET
              ? " 🎁 You've unlocked your prize!"
              : ` Only ${REFERRAL_TARGET - referralCount} more to go to earn an exclusive reward!`} */}
          </Typography>

          <Typography variant="body2" sx={{ mb: 2 }}>
            For each referral that signs up, both of you will receive a free month of Quick Tee!
          </Typography>

          <Box sx={{ width: "100%", mt: 2, mb: 3 }}>
            <LinearProgress
              variant="determinate"
              value={progress}
              sx={{
                height: 12,
                borderRadius: 5,
                "& .MuiLinearProgress-bar": { backgroundColor: "primary.main" },
              }}
            />
          </Box>
          {/* 
          {referralCount >= REFERRAL_TARGET ? (
            <Typography variant="h6" color="success.main">
              🎁 Congratulations! You’ve earned a reward! 🎁
            </Typography>
          ) : (
            <Button variant="contained" color="secondary" onClick={handleCopyReferralCode}>
              Share Your Referral Link
            </Button>
          )} */}
          <Button variant="contained" color="secondary" onClick={handleCopyReferralCode}>
            Share Your Referral Link
          </Button>

        </CardContent>
      </Card>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        message="Referral code copied to clipboard"
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      />
    </>
  );
};

export default ReferralProgress;
