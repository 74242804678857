import { Box, Typography } from "@mui/material";
import { motion } from "framer-motion";
import PricingCard from "./PricingCard";
import { useTheme } from '@mui/material/styles';

const Pricing = () => {
    const theme = useTheme();
    return (
        <Box
            sx={{
                textAlign: "center",
                py: 8,
                color: "white",
                backgroundColor: theme.palette.secondary.main,
                boxShadow: "0px 8px 20px rgba(0,0,0,0.1)",
            }}
        >

            {/* Title */}
            <Typography
                variant="h3"
                sx={{ fontWeight: 700, mb: 2 }}
                component={motion.div}
                initial={{ opacity: 0, y: -30 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.6 }}
            >
                Let's Go!
            </Typography>

            {/* Subtitle */}
            <Typography
                variant="h6"
                sx={{ fontWeight: 400, mb: 4 }}
                component={motion.div}
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.6, delay: 0.2 }}
            >
                Sign up for a free two week trial now! No credit card is needed.
            </Typography>

            <PricingCard />
        </Box>
    );
};

export default Pricing;
