import {
    Accordion, AccordionSummary, AccordionDetails,
    TextField,
    FormControl, MenuItem, Select, InputLabel, Box,
    Typography,
    Button
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import TimeRangeSlider from '../components/TimeRangeSlider';
import ToggleDays from '../components/DaysOfWeek';
import CourseSearch from './CourseSearch';

interface FilterFormProps {
    newFilter: any;
    setNewFilter: (filter: any) => void;
}

const FilterForm: React.FC<FilterFormProps> = ({
    newFilter,
    setNewFilter
}) => {

    const handleDaysChange = (selected: number[]) => {
        setNewFilter({ ...newFilter, days_of_week: selected.join(',') })
    };

    return (
        <div>
            <CourseSearch newFilter={newFilter} setNewFilter={setNewFilter} />
            <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                width="100%"
                sx={{ textAlign: "center", mt: 2 }}
            >
                    <Typography gutterBottom>Number of Players</Typography>

                <Box display="flex" alignItems="center" gap={2}>
                    <Button
                        variant="contained"
                        onClick={() => setNewFilter({ ...newFilter, num_of_players: Math.max(1, newFilter.num_of_players - 1) })}
                        disabled={newFilter.num_of_players <= 1}
                    >
                        -
                    </Button>

                    <Typography
                        variant="h4"
                        sx={{ minWidth: "50px", textAlign: "center" }}
                    >{newFilter.num_of_players}</Typography>

                    <Button
                        variant="contained"
                        onClick={() => setNewFilter({ ...newFilter, num_of_players: Math.min(4, newFilter.num_of_players + 1) })}
                        disabled={newFilter.num_of_players >= 4}
                    >
                        +
                    </Button>
                </Box>
            </Box>
            <TimeRangeSlider
                timeGte={newFilter.time_gte || '05:00'}
                timeLte={newFilter.time_lte || '19:00'}
                onChange={(gte: string, lte: string) => setNewFilter({ ...newFilter, time_gte: gte, time_lte: lte })}
            />

            <ToggleDays
                onChange={handleDaysChange}
                canEdit={true}
                highlightedDays={newFilter.days_of_week ? newFilter.days_of_week.split(',').map(Number) : []}
            />
            <br/>

            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="subtitle1">Advanced Filters (Optional)</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <FormControl fullWidth>
                        <InputLabel id="size-label">Size</InputLabel>
                        <Select
                            labelId="size-label"
                            value={newFilter.size}
                            onChange={(e) => setNewFilter({ ...newFilter, size: e.target.value as number })}
                            label="Size"
                        >
                            <MenuItem value={-1}>Any</MenuItem>
                            <MenuItem value={9}>9 holes</MenuItem>
                            <MenuItem value={18}>18 holes</MenuItem>
                        </Select>
                    </FormControl>

                    <Box display="flex" justifyContent="space-between" gap={2}>
                        <TextField
                            slotProps={{ inputLabel: { shrink: true } }}
                            margin="dense"
                            name="date_start"
                            label="Start Date"
                            type="date"
                            fullWidth
                            value={newFilter.date_start || ""}
                            onChange={(e) => setNewFilter({ ...newFilter, date_start: e.target.value })}
                            helperText="Leave blank to search all dates"
                        />
                        <TextField
                            slotProps={{ inputLabel: { shrink: true } }}
                            margin="dense"
                            name="date_end"
                            label="End Date"
                            type="date"
                            fullWidth
                            value={newFilter.date_end || ""}
                            onChange={(e) => setNewFilter({ ...newFilter, date_end: e.target.value })}
                            helperText="Leave blank to search all dates"
                        />
                    </Box>
                </AccordionDetails>
            </Accordion>
        </div>
    );
};

export default FilterForm;
