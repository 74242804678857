import { useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import apiClient from '../utils/apiClient';
import { useNavigate } from 'react-router-dom'; // Import useNavigate

// Initialize Stripe with your publishable key
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY!);

const Subscribe = ({ withTrial = false }: { withTrial?: boolean}) => {
  const navigate = useNavigate(); // Initialize useNavigate

  useEffect(() => {
    const handleSubscribe = async () => {
      try {
        const response = await apiClient.post('/create-checkout-session/', { trial: withTrial});

        // Redirect to Stripe Checkout
        const stripe = await stripePromise;
        await stripe!.redirectToCheckout({
          sessionId: response.data.id,
        });
      } catch (error) {
        console.error("Error in creating checkout session:", error);
        //  Instead of setting error message, perhaps redirect to a dedicated error page:
        navigate('/subscription-error'); // Redirect on error
      }
    };

    handleSubscribe(); // Call the function immediately upon component mount

  }, [navigate, withTrial]); // Add navigate and withTrial as dependencies

  return (
    <div>
      <p>Redirecting to Stripe...</p>
    </div>
  );
};

export default Subscribe;