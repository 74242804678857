import React, { useState, useMemo } from 'react';
import {
    Card,
    CardHeader,
    CardContent,
    Typography,
    Button,
    IconButton,
    CardActions,
    Grid2,
    Box
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import ToggleDays from './DaysOfWeek';
import LockIcon from '@mui/icons-material/Lock';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PersonIcon from '@mui/icons-material/Person';
import TimeRangeSlider from './TimeRangeSlider';

const MAX_COURSES_DISPLAYED = 2;

interface FilterProps {
    filter: any;
    openEditForm: (filter: any) => void;
    handleDeleteFilter: (filterId: number) => void;
    subscribed: boolean;
}

const FilterComponent: React.FC<FilterProps> = ({ filter, openEditForm, handleDeleteFilter, subscribed }) => {
    const [showAllCourses, setShowAllCourses] = useState(false);

    const courseNamesToDisplay = useMemo(() => {
        return showAllCourses ? filter.course_names : filter.course_names.slice(0, MAX_COURSES_DISPLAYED);
    }, [filter.course_names, showAllCourses]); // Dependency array includes filter.course_names and showAllCourses
    
    return (
        <Grid2 key={filter.id}>
            <Card sx={{
                display: 'flex',
                flexDirection: 'column',
                height: 410,
                width: 300,
                backgroundColor: subscribed ? 'white' : 'lightgrey',
                borderRadius: '20px'
            }} key={filter.id}>
                <CardHeader
                    sx={{
                        backgroundColor: 'primary.main',
                        color: 'white',
                    }}
                    avatar={
                        subscribed ? <CheckCircleIcon sx={{ marginRight: 0.5, color: 'success.main' }} /> : <LockIcon sx={{ marginRight: 0.5 }} />
                    }
                    title={
                        <>
                            {courseNamesToDisplay.map((courseName: string, index: number) => (
                                <React.Fragment key={index}>
                                    {courseName}
                                    {index < courseNamesToDisplay.length - 1 && <br />}
                                </React.Fragment>
                            ))}
                            {filter.course_names.length > MAX_COURSES_DISPLAYED && !showAllCourses && (
                                <>
                                    <br />
                                    <Button size="small" onClick={() => setShowAllCourses(true)} sx={{ color: 'white' }}>
                                        ...and {filter.course_names.length - MAX_COURSES_DISPLAYED} more
                                    </Button>
                                </>
                            )}
                        </>
                    }
                    subheader={<Box sx={{ display: 'flex', flexDirection: 'row' }}>
                        {(() => {
                            const icons = [];
                            for (let i = 0; i < filter.num_of_players; i++) {
                                icons.push(<PersonIcon key={i} />);
                            }
                            return icons;
                        })()}
                    </Box>}
                />
                <CardContent>
                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                        {filter.size > 0 ? `${filter.size} holes` : "Any course size"}
                    </Typography>

                    {filter.date_start && filter.date_end && (
                        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                            {`From ${filter.date_start} to ${filter.date_end}`}
                        </Typography>
                    )}
                    <TimeRangeSlider
                        timeGte={filter.time_gte || '05:00'}
                        timeLte={filter.time_lte || '19:00'}
                    />
                    {filter.days_of_week && (
                        <ToggleDays onChange={null} canEdit={false} highlightedDays={filter.days_of_week.split(',').map(Number)} />
                    )}
                </CardContent>
                <CardActions sx={{ mt: 'auto', justifyContent: 'space-between' }}>
                    <Button size="small" onClick={() => openEditForm(filter)}>Edit</Button>
                    <IconButton
                        size="small"
                        color="error"
                        aria-label="delete"
                        onClick={() => handleDeleteFilter(filter.id)}
                    >
                        <DeleteIcon />
                    </IconButton>
                </CardActions>
            </Card>
        </Grid2>
    );
};

export default FilterComponent;
