import {
    TextField,
} from '@mui/material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import axios from 'axios';
import { useEffect, useState, useCallback } from 'react';
import CourseRequest from './CourseRequest';
import { Course } from '../types/Course';

interface FilterFormProps {
    newFilter: any;
    setNewFilter: (filter: any) => void;
    onNewCourseRequest?: () => void;
}

const CourseSearch: React.FC<FilterFormProps> = ({
    newFilter,
    setNewFilter,
    onNewCourseRequest
}) => {

    const [courses, setCourses] = useState<Course[]>([]);
    const [newCourse, setNewCourse] = useState('');
    const [openModal, setOpenModal] = useState(false);
    const filter = createFilterOptions();
    const baseURL = process.env.REACT_APP_BACK_END_URL;


    const fetchCourses = useCallback(async () => {
        try {
            const response = await axios.get(`${baseURL}/courses/`, {
            });
            setCourses(response.data);
        } catch (error) {
            console.error("Error fetching courses:", error);
        }
    }, [baseURL]);

    const handleOpenModal = (course_name: string) => {
        setNewCourse(course_name);
        setOpenModal(true);
    }


    const handleCloseModal = () => {
        setOpenModal(false)
    };

    useEffect(() => {
        fetchCourses();
    }, []);

    return (
        <div>
            <Autocomplete
                multiple
                options={courses}
                getOptionLabel={(course: Course) => {
                    if (typeof course === 'string') {
                        return course;
                    }
                    if (course.inputValue) {
                        return course.inputValue;
                    }
                    return course.formatted_name;
                }}
                value={courses.filter((course: any) => newFilter.courses?.includes(course.id)) || []}
                onChange={(event, newValues) => {
                    if (!newValues) return;
                    if (newValues.length === 0) {
                        // Handle the case where all courses are cleared
                        setNewFilter({ ...newFilter, courses: [] }); // Clear the courses array
                        return;
                    }
                    const lastSelected = newValues[newValues.length - 1];
                    if (lastSelected.inputValue) {
                        handleOpenModal(lastSelected.inputValue);
                    } else {
                        if (newValues.length <= 5) {
                            setNewFilter({ ...newFilter, courses: newValues.map((c: any) => c.id) });
                        }
                    }
                }}
                filterSelectedOptions
                renderOption={(props, option: Course) => (
                    <li {...props} key={option.id}>
                        {option.formatted_name}
                    </li>
                )}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        required
                        label="Select Up to 5 Courses"
                        variant="outlined"
                        margin="normal"
                        fullWidth
                    />
                )}
                filterOptions={(options: Course[], params: any) => {
                    const filtered = filter(options, params) as Course[];
                    const { inputValue } = params;
                    const isExisting = options.some((option) => inputValue === option.formatted_name);
                    if (inputValue !== '' && !isExisting) {
                        filtered.push({
                            inputValue,
                            formatted_name: `Request to add the course "${inputValue}"`,
                        });
                    }
                    return filtered;
                }}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
            />

            {/* Modal for requesting a missing course */}
            <CourseRequest onNewCourseRequest={onNewCourseRequest} handleCloseModal={handleCloseModal} courseName={newCourse} modalOpen={openModal} />
        </div>
    );

};

export default CourseSearch;