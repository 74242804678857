import React, { useState, useEffect } from 'react';
import SideMenu from './SideMenu';
import { Box, CircularProgress } from '@mui/material';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import TopMenuBar from './TopMenuBar';
import apiClient from '../utils/apiClient';
import { isUserSignedIn } from '../utils/authUtils';
import { useAccount } from '../contexts/AccountContext';



const AppLayout = ({ children }: { children?: React.ReactNode }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const theme = useTheme();
    const [sideMenuOpen, setSideMenuOpen] = useState(true);
    const [isLoading, setIsLoading] = useState(true);
    const { setSubscriptionStatus, setFirstName, setLastName, setEmail, setPhoneNumber, setReferralCode, setGroup, setCompletedReferrals, group } = useAccount();

    // Media query to detect mobile view
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));


    const toggleSideMenu = () => {
        setSideMenuOpen(!sideMenuOpen);
    };

    useEffect(() => {
        setIsLoading(true);
        const preregisterPaths = ['/preregister'];

        const hideSideMenuPaths = ['/new-user-survey', '/preregister', '/preregistered', '/welcome'];

        if (isMobile || hideSideMenuPaths.includes(location.pathname)) {
            setSideMenuOpen(false);
        } else {
            setSideMenuOpen(true);
        }

        // Check if the user is preregistered and redirect if needed
        const fetchUserData = async () => {
            try {
                const response = await apiClient.get('/account/');
                const { first_name, last_name, email, phone_number, status, referral_code, group, completed_referrals } = response.data;
                setGroup(group);
                setSubscriptionStatus(status);
                setFirstName(first_name);
                setLastName(last_name);
                setEmail(email);
                setPhoneNumber(phone_number);
                setReferralCode(referral_code);
                setCompletedReferrals(completed_referrals);
                
                if ((group === 'preregister' || (status == 'pending' && group === 'default')) && (!preregisterPaths.includes(location.pathname))) {
                    navigate('/preregistered');  // Redirect to preregistered page
                }
            } catch (error: any) {
                console.log('Failed to load user data');
            } finally {
                setIsLoading(false); // Stop loading after data fetch
            }
        };

        fetchUserData();
    }, [location.pathname, navigate]);


    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100vh',
                overflowX: 'hidden', // Prevent horizontal overflow on small screens
            }}
        >
            {/* Top Menu Bar */}
            <TopMenuBar
                showMenuIcon={true}
                onToggleSideMenu={toggleSideMenu}
                isLoggedIn={isUserSignedIn()}
            />

            {/* Main Layout with Side Menu and Content */}
            <Box
                sx={{
                    display: 'flex',
                    flexGrow: 1,
                    mt: '64px',
                    backgroundColor:'#f9fafb',
                    flexDirection: { xs: 'column', md: 'row' }, // Stack on mobile, side-by-side on desktop
                    overflowX: 'hidden', // Prevent horizontal scrolling
                }}
            >
                {/* Side Menu */}
                {sideMenuOpen && (
                    <Box
                        sx={{
                            width: { xs: '100%', md: '250px' }, // Full width on mobile, fixed width on desktop
                            flexShrink: 0,
                            m: 0,
                            p: 0
                        }}
                    >
                        <SideMenu
                            isAdmin={group == "admin"}
                        />
                    </Box>
                )}

                {/* Main Content */}
                {isLoading ? (
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            p: 0,
                            flexGrow: 1
                        }}
                    >
                        <CircularProgress size={80} thickness={6} />
                    </Box>
                ) : (
                    <Box component="main" sx={{ flexGrow: 1, p: 0 }}>
                        {children}
                        <Outlet />
                    </Box>
                )}

            </Box>
        </Box>
    );
};

export default AppLayout;
